let timer:any

export default {
  _createCallbackCount:0,
  createCallback(fn:any) {
    if (!this._createCallbackCount) {
      this._createCallbackCount = 1;
    }

    const names = '_' + +new Date() + '_' + this._createCallbackCount++;
    // @ts-ignore
    window[names] =  (data:any)=> {
        console.log(data)
      fn && fn(data);
      // @ts-ignore
      delete window[names];
    };
    return names;
  },
  domReady(fn:any) {
    const readyState = document.readyState;
    if (readyState === 'interactive' || readyState === 'complete') {
      fn && fn();
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        fn && fn();
      });
    }
  },
  startTimer(op :any={}) {
    let time = op.time || 60;
    timer = window.setInterval(() => {
      op.onThrob && op.onThrob(time);
      time--;
      if (time < 1) {
        op.onOver && op.onOver();
        window.clearInterval(timer);
      }
    }, 1000);
  },
  clearTimer() {
	if (timer) {
		window.clearInterval(timer);
	}
  }
};
